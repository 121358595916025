.react-phone-number-input__input {
  font-family: 'Gotham SSm A', 'Gotham SSm B', 'Helvetica Neue', Helvetica,
    Arial, sans-serif;
  font-size: 14px;
  font-weight: 300;
  height: 48px;
  padding-bottom: 14px;
  padding-top: 14px;
}

.PhoneInputInput {
  background-color: rgb(255, 255, 255);
  border-bottom: none;
  border-color: rgb(235, 235, 235);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  box-sizing: border-box;
  color: rgb(34, 34, 34);
  height: 48px;
  outline: none;
  overflow: hidden;
  padding: 14px;
  transition: 0.3s;
  width: 100%;
}

.PhoneInputInput.error {
  border-color: rgb(208, 2, 27);
}

.PhoneInputInput.readonly {
  background-color: rgb(246, 246, 246);
  border-color: rgb(246, 246, 246);
  color: rgb(170, 170, 170);
  cursor: 'not-allowed';
}

.PhoneInputInput.readonly:focus {
  border-color: rgb(246, 246, 246);
}

.PhoneInputInput.small {
  font-size: 12px;
}

.PhoneInputInput:focus {
  border-color: rgb(0, 170, 228);
}
