.rc-time-picker-input, .rc-time-picker-panel-input {
  color: #222222;
  font-family: system-ui, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 12px;
  font-weight: 300;
}

.rc-time-picker-input {
  border: 0;
  box-sizing: border-box;
  height: auto;
  outline: none;
  padding: 0;
  max-height: 48px;
}

