.DayPicker {
  font-size: 1rem;
  display: inline-block;
}

.DayPicker-wrapper {
  -webkit-user-select: none;
  user-select: none;
  flex-direction: row;
  padding-bottom: 1em;
  position: relative;
}

.DayPicker-Months {
  flex-wrap: wrap;
  justify-content: center;
  display: flex;
}

.DayPicker-Month {
  border-spacing: 0;
  border-collapse: collapse;
  -webkit-user-select: none;
  user-select: none;
  margin: 1em 1em 0;
  display: table;
}

.DayPicker-NavButton {
  color: #8b9898;
  cursor: pointer;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 50%;
  width: 1.25em;
  height: 1.25em;
  margin-top: 2px;
  display: inline-block;
  position: absolute;
  top: 1em;
  left: auto;
  right: 1.5em;
}

.DayPicker-NavButton:hover {
  opacity: .8;
}

.DayPicker-NavButton--prev {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAVVJREFUWAnN2G0KgjAYwPHpGfRkaZeqvgQaK+hY3SUHrk1YzNLay/OiEFp92I+/Mp2F2Mh2lLISWnflFjzH263RQjzMZ19wgs73ez0o1WmtW+dgA01VxrE3p6l2GLsnBy1VYQOtVSEH/atCCgqpQgKKqYIOiq2CBkqtggLKqQIKgqgCBjpJ2Y5CdJ+zrT9A7HHSTA1dxUdHgzCqJIEwq0SDsKsEg6iqBIEoq/wEcVRZBXFV+QJxV5mBtlDFB5VjYTaGZ2sf4R9PM7U9ZU+lLuaetPP/5Die3ToO1+u+MKtHs06qODB2zBnI/jBd4MPQm1VkY79Tb18gB+C62FdBFsZR6yeIo1YQiLJWMIiqVjQIu1YSCLNWFgijVjYIuhYYCKoWKAiiFgoopxYaKLUWOii2FgkophYp6F3r42W5A9s9OcgNvva8xQaysKXlFytoqdYmQH6tF3toSUo0INq9AAAAAElFTkSuQmCC");
  margin-right: 1.5em;
}

.DayPicker-NavButton--next {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAXRJREFUWAnN119ugjAcwPHWzJ1gnmxzB/BBE0n24m4xfNkTaOL7wOtsl3AXMMb+Vjaa1BG00N8fSEibPpAP3xAKKs2yjzTPH9RAjhEo9WzPr/Vm8zgE0+gXATAxxuxtqeJ9t5tIwv5AtQAApsfT6TPdbp+kUBcgVwvO51KqVhMkXKsVJFXrOkigVhCIs1Y4iKlWZxB1rX4gwlpRIIpa8SDkWmggrFq4IIRaJKCYWnSgnrXIQV1r8YD+1Vrn+bReagysIFfLABRt31v8oBu1xEBttfRbltmfjgEcWh9snUS2kNdBK6WN1vrOWxObWsz+fjxevsxmB1GQDfINWiev83nhaoiB/CoOU438oPrhXS0WpQ9xc1ZQWxWHqUYe0I0qrKCQKjygDlXIQV2r0IF6ViEBxVTBBSFUQQNhVYkHIVeJAtkNsbQ7c1LtzP6FsObhb2rCKv7NBIGoq4SDmKoEgTirXAcJVGkFSVVpgoSrXICGUMUH/QBZNSUy5XWUhwAAAABJRU5ErkJggg==");
}

.DayPicker-NavButton--interactionDisabled {
  display: none;
}

.DayPicker-Caption {
  text-align: left;
  margin-bottom: .5em;
  padding: 0 .5em;
  display: table-caption;
}

.DayPicker-Caption > div {
  font-size: 1.15em;
  font-weight: 500;
}

.DayPicker-Weekdays {
  margin-top: 1em;
  display: table-header-group;
}

.DayPicker-WeekdaysRow {
  display: table-row;
}

.DayPicker-Weekday {
  color: #8b9898;
  text-align: center;
  padding: .5em;
  font-size: .875em;
  display: table-cell;
}

.DayPicker-Weekday abbr[title] {
  border-bottom: none;
  text-decoration: none;
}

.DayPicker-Body {
  display: table-row-group;
}

.DayPicker-Week {
  display: table-row;
}

.DayPicker-Day {
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  border-radius: 50%;
  padding: .5em;
  display: table-cell;
}

.DayPicker-WeekNumber {
  color: #8b9898;
  vertical-align: middle;
  text-align: right;
  cursor: pointer;
  border-right: 1px solid #eaecec;
  min-width: 1em;
  padding: .5em;
  font-size: .75em;
  display: table-cell;
}

.DayPicker--interactionDisabled .DayPicker-Day {
  cursor: default;
}

.DayPicker-Footer {
  padding-top: .5em;
}

.DayPicker-TodayButton {
  box-shadow: none;
  color: #4a90e2;
  cursor: pointer;
  background-color: #0000;
  background-image: none;
  border: none;
  font-size: .875em;
}

.DayPicker-Day--today {
  color: #d0021b;
  font-weight: 700;
}

.DayPicker-Day--outside {
  color: #8b9898;
  cursor: default;
}

.DayPicker-Day--disabled {
  color: #dce0e0;
  cursor: default;
}

.DayPicker-Day--sunday {
  background-color: #f7f8f8;
}

.DayPicker-Day--sunday:not(.DayPicker-Day--today) {
  color: #dce0e0;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  color: #f0f8ff;
  background-color: #4a90e2;
  position: relative;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
  background-color: #51a0fa;
}

.DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
  background-color: #f0f8ff;
}

.DayPickerInput {
  display: inline-block;
}

.DayPickerInput-OverlayWrapper {
  position: relative;
}

.DayPickerInput-Overlay {
  z-index: 1;
  background: #fff;
  position: absolute;
  left: 0;
  box-shadow: 0 2px 5px #00000026;
}

.lh-date-time-picker .DayPicker {
  outline: none;
  font-size: 13px;
}

.lh-date-time-picker .DayPicker-Caption > div {
  text-align: center;
  font-size: 12px;
}

.lh-date-time-picker .DayPicker-Day {
  background: #fff;
  border: 1px solid #eee;
  border-radius: 0 !important;
}

.lh-date-time-picker .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  color: #fff;
  background: #00aae4;
}

.lh-date-time-picker .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
  background: #00aae4;
}

.lh-date-time-picker .DayPicker-Day.DayPicker-Day--disabled, .DayPicker-Day.DayPicker-Day--beforeSelectableRange {
  color: #dce0e0;
  cursor: not-allowed;
  background: #f5f5f5;
}

.lh-date-time-picker .DayPicker-Day.DayPicker-Day--activeRangeEnd:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):not(.DayPicker-Day--selected) {
  background: #f0fbff;
}

.lh-date-time-picker .DayPicker-Day.DayPicker-Day--afterActiveRange {
  color: #dce0e0;
  cursor: not-allowed;
  background: #fff;
}

.lh-date-time-picker .DayPicker-Day--hoverRange:not(.DayPicker-Day--outside):not(.DayPicker-Day--selected) {
  background: #f0fbff !important;
}

.lh-date-time-picker .DayPicker-NavButton {
  color: #8b9898;
  cursor: pointer;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 50%;
  width: 1.25em;
  height: 1.25em;
  margin-top: 2px;
  display: inline-block;
  position: absolute;
  top: 1em;
  left: auto;
  right: 1.5em;
}

.lh-date-time-picker .DayPicker-NavButton:hover {
  opacity: .8;
}

.lh-date-time-picker .DayPicker-NavButton--prev {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAVVJREFUWAnN2G0KgjAYwPHpGfRkaZeqvgQaK+hY3SUHrk1YzNLay/OiEFp92I+/Mp2F2Mh2lLISWnflFjzH263RQjzMZ19wgs73ez0o1WmtW+dgA01VxrE3p6l2GLsnBy1VYQOtVSEH/atCCgqpQgKKqYIOiq2CBkqtggLKqQIKgqgCBjpJ2Y5CdJ+zrT9A7HHSTA1dxUdHgzCqJIEwq0SDsKsEg6iqBIEoq/wEcVRZBXFV+QJxV5mBtlDFB5VjYTaGZ2sf4R9PM7U9ZU+lLuaetPP/5Die3ToO1+u+MKtHs06qODB2zBnI/jBd4MPQm1VkY79Tb18gB+C62FdBFsZR6yeIo1YQiLJWMIiqVjQIu1YSCLNWFgijVjYIuhYYCKoWKAiiFgoopxYaKLUWOii2FgkophYp6F3r42W5A9s9OcgNvva8xQaysKXlFytoqdYmQH6tF3toSUo0INq9AAAAAElFTkSuQmCC");
  margin-right: 1.5em;
}

.lh-date-time-picker .DayPicker-NavButton--next {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAXRJREFUWAnN119ugjAcwPHWzJ1gnmxzB/BBE0n24m4xfNkTaOL7wOtsl3AXMMb+Vjaa1BG00N8fSEibPpAP3xAKKs2yjzTPH9RAjhEo9WzPr/Vm8zgE0+gXATAxxuxtqeJ9t5tIwv5AtQAApsfT6TPdbp+kUBcgVwvO51KqVhMkXKsVJFXrOkigVhCIs1Y4iKlWZxB1rX4gwlpRIIpa8SDkWmggrFq4IIRaJKCYWnSgnrXIQV1r8YD+1Vrn+bReagysIFfLABRt31v8oBu1xEBttfRbltmfjgEcWh9snUS2kNdBK6WN1vrOWxObWsz+fjxevsxmB1GQDfINWiev83nhaoiB/CoOU438oPrhXS0WpQ9xc1ZQWxWHqUYe0I0qrKCQKjygDlXIQV2r0IF6ViEBxVTBBSFUQQNhVYkHIVeJAtkNsbQ7c1LtzP6FsObhb2rCKv7NBIGoq4SDmKoEgTirXAcJVGkFSVVpgoSrXICGUMUH/QBZNSUy5XWUhwAAAABJRU5ErkJggg==");
}

.lh-date-time-picker .DayPicker-NavButton--interactionDisabled {
  display: none;
}

:root {
  --PhoneInput-color--focus: #03b2cb;
  --PhoneInputInternationalIconPhone-opacity: .8;
  --PhoneInputInternationalIconGlobe-opacity: .65;
  --PhoneInputCountrySelect-marginRight: .35em;
  --PhoneInputCountrySelectArrow-width: .3em;
  --PhoneInputCountrySelectArrow-marginLeft: var(--PhoneInputCountrySelect-marginRight);
  --PhoneInputCountrySelectArrow-borderWidth: 1px;
  --PhoneInputCountrySelectArrow-opacity: .45;
  --PhoneInputCountrySelectArrow-color: currentColor;
  --PhoneInputCountrySelectArrow-color--focus: var(--PhoneInput-color--focus);
  --PhoneInputCountrySelectArrow-transform: rotate(45deg);
  --PhoneInputCountryFlag-aspectRatio: 1.5;
  --PhoneInputCountryFlag-height: 1em;
  --PhoneInputCountryFlag-borderWidth: 1px;
  --PhoneInputCountryFlag-borderColor: #00000080;
  --PhoneInputCountryFlag-borderColor--focus: var(--PhoneInput-color--focus);
  --PhoneInputCountryFlag-backgroundColor--loading: #0000001a;
}

.PhoneInput {
  align-items: center;
  display: flex;
}

.PhoneInputInput {
  flex: 1;
  min-width: 0;
}

.PhoneInputCountryIcon {
  width: calc(var(--PhoneInputCountryFlag-height) * var(--PhoneInputCountryFlag-aspectRatio));
  height: var(--PhoneInputCountryFlag-height);
}

.PhoneInputCountryIcon--square {
  width: var(--PhoneInputCountryFlag-height);
}

.PhoneInputCountryIcon--border {
  background-color: var(--PhoneInputCountryFlag-backgroundColor--loading);
  box-shadow: 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor), inset 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor);
}

.PhoneInputCountryIconImg {
  width: 100%;
  height: 100%;
  display: block;
}

.PhoneInputInternationalIconPhone {
  opacity: var(--PhoneInputInternationalIconPhone-opacity);
}

.PhoneInputInternationalIconGlobe {
  opacity: var(--PhoneInputInternationalIconGlobe-opacity);
}

.PhoneInputCountry {
  margin-right: var(--PhoneInputCountrySelect-marginRight);
  align-self: stretch;
  align-items: center;
  display: flex;
  position: relative;
}

.PhoneInputCountrySelect {
  z-index: 1;
  opacity: 0;
  cursor: pointer;
  border: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.PhoneInputCountrySelect[disabled], .PhoneInputCountrySelect[readonly] {
  cursor: default;
}

.PhoneInputCountrySelectArrow {
  content: "";
  width: var(--PhoneInputCountrySelectArrow-width);
  height: var(--PhoneInputCountrySelectArrow-width);
  margin-left: var(--PhoneInputCountrySelectArrow-marginLeft);
  border-style: solid;
  border-color: var(--PhoneInputCountrySelectArrow-color);
  border-top-width: 0;
  border-bottom-width: var(--PhoneInputCountrySelectArrow-borderWidth);
  border-left-width: 0;
  border-right-width: var(--PhoneInputCountrySelectArrow-borderWidth);
  transform: var(--PhoneInputCountrySelectArrow-transform);
  opacity: var(--PhoneInputCountrySelectArrow-opacity);
  display: block;
}

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon + .PhoneInputCountrySelectArrow {
  opacity: 1;
  color: var(--PhoneInputCountrySelectArrow-color--focus);
}

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon--border {
  box-shadow: 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor--focus), inset 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor--focus);
}

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon .PhoneInputInternationalIconGlobe {
  opacity: 1;
  color: var(--PhoneInputCountrySelectArrow-color--focus);
}

.react-phone-number-input__input {
  height: 48px;
  padding-top: 14px;
  padding-bottom: 14px;
  font-family: Gotham SSm A, Gotham SSm B, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 300;
}

.PhoneInputInput {
  box-sizing: border-box;
  color: #222;
  background-color: #fff;
  border: 1px solid #ebebeb;
  border-radius: 4px;
  outline: none;
  width: 100%;
  height: 48px;
  padding: 14px;
  transition: all .3s;
  overflow: hidden;
}

.PhoneInputInput.error {
  border-color: #d0021b;
}

.PhoneInputInput.readonly {
  color: #aaa;
  cursor: "not-allowed";
  background-color: #f6f6f6;
  border-color: #f6f6f6;
}

.PhoneInputInput.readonly:focus {
  border-color: #f6f6f6;
}

.PhoneInputInput.small {
  font-size: 12px;
}

.PhoneInputInput:focus {
  border-color: #00aae4;
}

::-webkit-input-placeholder {
  color: #aaa;
}

::placeholder {
  color: #aaa;
}

:-ms-input-placeholder {
  color: #aaa;
}

:-moz-placeholder {
  color: #aaa;
}

.rc-time-picker {
  box-sizing: border-box;
  display: inline-block;
  position: relative;
}

.rc-time-picker * {
  box-sizing: border-box;
}

.rc-time-picker-clear {
  cursor: pointer;
  text-align: center;
  width: 20px;
  height: 20px;
  margin: 0;
  line-height: 20px;
  position: absolute;
  top: 3px;
  right: 6px;
  overflow: hidden;
}

.rc-time-picker-clear-icon:after {
  content: "x";
  color: #aaa;
  width: 20px;
  height: 20px;
  font-size: 12px;
  font-style: normal;
  line-height: 1;
  transition: color .3s;
  display: inline-block;
}

.rc-time-picker-clear-icon:hover:after {
  color: #666;
}

.rc-time-picker-input {
  cursor: text;
  color: #666;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  width: 100%;
  height: 28px;
  padding: 4px 7px;
  font-size: 12px;
  line-height: 1.5;
  transition: border .2s cubic-bezier(.645, .045, .355, 1), background .2s cubic-bezier(.645, .045, .355, 1), box-shadow .2s cubic-bezier(.645, .045, .355, 1);
  display: inline-block;
  position: relative;
}

.rc-time-picker-input[disabled] {
  color: #ccc;
  cursor: not-allowed;
  background: #f7f7f7;
}

.rc-time-picker-panel {
  z-index: 1070;
  box-sizing: border-box;
  width: 170px;
  position: absolute;
}

.rc-time-picker-panel * {
  box-sizing: border-box;
}

.rc-time-picker-panel-inner {
  text-align: left;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  font-size: 12px;
  line-height: 1.5;
  list-style: none;
  display: inline-block;
  position: relative;
  box-shadow: 0 1px 5px #ccc;
}

.rc-time-picker-panel-narrow {
  max-width: 113px;
}

.rc-time-picker-panel-input {
  cursor: auto;
  border: 1px solid #0000;
  outline: 0;
  width: 100%;
  margin: 0;
  padding: 0;
  line-height: 1.5;
}

.rc-time-picker-panel-input-wrap {
  box-sizing: border-box;
  border-bottom: 1px solid #e9e9e9;
  padding: 6px;
  position: relative;
}

.rc-time-picker-panel-input-invalid {
  border-color: red;
}

.rc-time-picker-panel-select {
  float: left;
  box-sizing: border-box;
  border: 0 solid #e9e9e9;
  border-width: 0 1px;
  width: 56px;
  max-height: 144px;
  margin-left: -1px;
  font-size: 12px;
  position: relative;
  overflow-y: auto;
}

.rc-time-picker-panel-select-active {
  overflow-y: auto;
}

.rc-time-picker-panel-select:first-child {
  border-left: 0;
  margin-left: 0;
}

.rc-time-picker-panel-select:last-child {
  border-right: 0;
}

.rc-time-picker-panel-select ul {
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
}

.rc-time-picker-panel-select li {
  text-align: left;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  width: 100%;
  height: 24px;
  margin: 0;
  padding: 0 0 0 16px;
  line-height: 24px;
  list-style: none;
}

.rc-time-picker-panel-select li:hover {
  background: #edfaff;
}

li.rc-time-picker-panel-select-option-selected {
  background: #f7f7f7;
  font-weight: bold;
}

li.rc-time-picker-panel-select-option-disabled {
  color: #ccc;
}

li.rc-time-picker-panel-select-option-disabled:hover {
  cursor: not-allowed;
  background: none;
}

.rc-time-picker-input, .rc-time-picker-panel-input {
  color: #222;
  font-family: system-ui, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 12px;
  font-weight: 300;
}

.rc-time-picker-input {
  box-sizing: border-box;
  border: 0;
  outline: none;
  height: auto;
  max-height: 48px;
  padding: 0;
}

.wysiwyg-toolbar {
  border: 1px solid #ebebeb;
  border-radius: 4px;
  height: 34px;
  margin-bottom: 5px;
  padding: 5px;
  font-family: "system-ui", Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 34px;
  display: flex;
}

.wysiwyg-toolbar .rdw-block-wrapper, .wysiwyg-toolbar .rdw-inline-wrapper, .wysiwyg-toolbar .rdw-list-wrapper, .rdw-link-wrapper {
  display: flex;
}

.rdw-link-modal-input {
  border: 1px solid #ebebeb;
  border-radius: 3px;
  width: 90%;
  display: flex;
}

.rdw-link-modal-buttonsection {
  justify-content: space-between;
  height: 34px;
  padding: 5px;
  display: flex;
}

.rdw-link-modal-btn {
  height: 35px;
  cursor: "pointer";
  color: #545454;
  border-style: none;
  border-width: 0;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  font-size: 12px;
  font-weight: 500;
  line-height: 40px;
  display: flex;
}

.rdw-link-modal-btn:first-child {
  color: #fff;
  background-color: #00aae4;
}

img.rdw-link-decorator-icon {
  cursor: pointer;
  height: 10px;
  padding-left: 2px;
  padding-right: 2px;
}

.popup-modal {
  background-color: #fff;
  border: 1px solid #ebebeb;
  border-radius: 3px;
  justify-content: center;
  width: 15%;
  margin-top: -6px;
  margin-left: 35px;
  padding: 5px;
  font-family: "system-ui", Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 12px;
  font-weight: 400;
  display: block;
  position: absolute;
}

.wysiwyg-toolbar .rdw-option-wrapper, .wysiwyg-toolbar .rdw-block-wrapper {
  text-align: center;
  border: 1px solid #ebebeb;
  border-radius: 3px;
  justify-content: center;
  min-width: 30px;
  margin-right: 4px;
  display: flex;
}

.wysiwyg-toolbar .rdw-option-wrapper img {
  align-self: center;
}

.wysiwyg-toolbar .rdw-block-wrapper, .wysiwyg-toolbar .rdw-option-wrapper:hover {
  cursor: pointer;
}

.wysiwyg-toolbar .rdw-block-wrapper {
  padding-left: 5px;
  padding-right: 5px;
}

.wysiwyg-toolbar .rdw-option-active {
  background: #ebebeb;
}

.rdw-block-dropdown {
  width: 80px;
  font-size: 12px;
}

.rdw-block-dropdown .rdw-dropdown-selectedtext {
  white-space: nowrap;
  display: block;
}

.rdw-editor-wrapper {
  width: 100%;
  display: block;
}

.blockOptions {
  z-index: 1000;
  background: #fff;
  margin-top: 3px;
  margin-left: -3px;
  margin-right: -3px;
  padding: 0;
  list-style: none;
  position: relative;
  box-shadow: 0 4px 14px #0003;
}

.blockOptions li {
  border-bottom: 1px solid #ebebeb;
}

.blockOptions li:hover {
  background: #f6f6f6;
}

.wysiwyg-editor {
  box-sizing: border-box;
  border: 1px solid #ebebeb;
  border-radius: 4px;
  min-height: 160px;
  padding: 10px;
  font-family: "system-ui", Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 12px;
  display: block;
}

.wysiwyg-editor h1 {
  word-break: break-word;
  margin-top: 0;
  font-size: 20px;
}

.wysiwyg-editor h2 {
  color: #333;
  word-break: break-word;
  margin-top: 0;
  font-size: 16px;
}

.wysiwyg-editor ul, ol {
  word-break: break-word;
  padding-left: 20px;
}

.wysiwyg-editor span {
  word-break: break-word;
  height: 20px;
  font-family: "system-ui", Helvetica Neue, Helvetica, Arial, sans-serif;
}

.wysiwyg-editor span a, .wysiwyg-editor span a:hover {
  word-break: break-word;
  color: #00aae4;
  font-family: "system-ui", Helvetica Neue, Helvetica, Arial, sans-serif;
}

.dropdownButton:hover {
  background-color: #f6f6f6 !important;
}

.filter-dropdown input:focus {
  outline: 0;
}

.vis-timeline {
  border-width: 1px 0 0;
}

.vis-text {
  font-size: 10px;
}

.vis-text.vis-major {
  margin-top: -3px;
}

.vis-item.vis-background {
  background-color: #d0021b0d;
}

.vis-custom-time {
  background-color: #56ad56;
}

.vis-custom-time:after {
  content: "";
  border: 9px solid #0000;
  border-top-color: #56ad56;
  border-bottom: 0 solid #56ad56;
  width: 0;
  display: block;
  position: absolute;
  top: 0;
  left: -8px;
}

.vis-grid.vis-minor {
  bottom: 0;
  border-color: #00aae4 !important;
  height: 10px !important;
  top: auto !important;
}

.vis-panel.vis-bottom {
  top: 27px !important;
}

.vis-text.vis-minor {
  margin-left: -15px;
}

.vis-panel.vis-center {
  border: 0;
  height: 60px !important;
}

.vis-item.vis-background {
  height: 60px !important;
}

.vis-content {
  top: 0 !important;
}

.vis-itemset {
  height: 60px !important;
}

.vis-shadow {
  display: none !important;
}

.vis-current-time {
  background: #d0021b;
}

.vis-item.vis-background.timeBackground {
  z-index: -200;
  bottom: 0;
  right: 0;
  background: #00beff80 !important;
  height: 5px !important;
}

.vis-item.vis-background.exception {
  border-right: "1px solid";
  border-left: "1px solid";
  border-radius: 10px;
  top: 12px;
  height: 10px !important;
}

.vis .overlay {
  z-index: 10;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.vis-active {
  box-shadow: 0 0 10px #86d5f8;
}

.vis [class*="span"] {
  width: auto;
  min-height: 0;
}

div.vis-configuration {
  float: left;
  font-size: 12px;
  display: block;
  position: relative;
}

div.vis-configuration-wrapper {
  width: 700px;
  display: block;
}

div.vis-configuration-wrapper:after {
  clear: both;
  content: "";
  display: block;
}

div.vis-configuration.vis-config-option-container {
  background-color: #fff;
  border: 2px solid #f7f8fa;
  border-radius: 4px;
  width: 495px;
  margin-top: 20px;
  padding-left: 5px;
  display: block;
  left: 10px;
}

div.vis-configuration.vis-config-button {
  vertical-align: middle;
  cursor: pointer;
  background-color: #f7f8fa;
  border: 2px solid #ceced0;
  border-radius: 4px;
  width: 495px;
  height: 25px;
  margin-top: 20px;
  margin-bottom: 30px;
  padding-left: 5px;
  line-height: 25px;
  display: block;
  left: 10px;
}

div.vis-configuration.vis-config-button.hover {
  color: #fff;
  background-color: #4588e6;
  border: 2px solid #214373;
}

div.vis-configuration.vis-config-item {
  float: left;
  vertical-align: middle;
  width: 495px;
  height: 25px;
  line-height: 25px;
  display: block;
}

div.vis-configuration.vis-config-item.vis-config-s2 {
  background-color: #f7f8fa;
  border-radius: 3px;
  padding-left: 5px;
  left: 10px;
}

div.vis-configuration.vis-config-item.vis-config-s3 {
  background-color: #e4e9f0;
  border-radius: 3px;
  padding-left: 5px;
  left: 20px;
}

div.vis-configuration.vis-config-item.vis-config-s4 {
  background-color: #cfd8e6;
  border-radius: 3px;
  padding-left: 5px;
  left: 30px;
}

div.vis-configuration.vis-config-header {
  font-size: 18px;
  font-weight: 700;
}

div.vis-configuration.vis-config-label {
  width: 120px;
  height: 25px;
  line-height: 25px;
}

div.vis-configuration.vis-config-label.vis-config-s3 {
  width: 110px;
}

div.vis-configuration.vis-config-label.vis-config-s4 {
  width: 100px;
}

div.vis-configuration.vis-config-colorBlock {
  cursor: pointer;
  border: 1px solid #444;
  border-radius: 2px;
  width: 30px;
  height: 19px;
  margin: 0;
  padding: 0;
  top: 1px;
}

input.vis-configuration.vis-config-checkbox {
  left: -5px;
}

input.vis-configuration.vis-config-rangeinput {
  pointer-events: none;
  width: 60px;
  margin: 0;
  padding: 1px;
  position: relative;
  top: -5px;
}

input.vis-configuration.vis-config-range {
  -webkit-appearance: none;
  background-color: #0000;
  border: 0 solid #fff;
  width: 300px;
  height: 20px;
}

input.vis-configuration.vis-config-range::-webkit-slider-runnable-track {
  background: -o-linear-gradient(top, #dedede 0, #c8c8c8 99%);
  background: -ms-linear-gradient(top, #dedede 0, #c8c8c8 99%);
  background: -moz-linear-gradient(top, #dedede 0, #c8c8c8 99%);
  background: -o-linear-gradient(top, #dedede 0, #c8c8c8 99%);
  background: linear-gradient(#dedede 0, #c8c8c8 99%);
  border: 1px solid #999;
  border-radius: 3px;
  width: 300px;
  height: 5px;
  box-shadow: 0 0 3px #aaa;
}

input.vis-configuration.vis-config-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  background: -o-linear-gradient(top, #3876c2 0, #385380 100%);
  background: -ms-linear-gradient(top, #3876c2 0, #385380 100%);
  background: -moz-linear-gradient(top, #3876c2 0, #385380 100%);
  background: -o-linear-gradient(top, #3876c2 0, #385380 100%);
  background: linear-gradient(#3876c2 0, #385380 100%);
  border: 1px solid #14334b;
  border-radius: 50%;
  width: 17px;
  height: 17px;
  margin-top: -7px;
  box-shadow: 0 0 1px #111927;
}

input.vis-configuration.vis-config-range:focus {
  outline: 0;
}

input.vis-configuration.vis-config-range:focus::-webkit-slider-runnable-track {
  background: -o-linear-gradient(top, #9d9d9d 0, #c8c8c8 99%);
  background: -ms-linear-gradient(top, #9d9d9d 0, #c8c8c8 99%);
  background: -moz-linear-gradient(top, #9d9d9d 0, #c8c8c8 99%);
  background: -o-linear-gradient(top, #9d9d9d 0, #c8c8c8 99%);
  background: linear-gradient(#9d9d9d 0, #c8c8c8 99%);
}

input.vis-configuration.vis-config-range::-moz-range-track {
  background: -o-linear-gradient(top, #dedede 0, #c8c8c8 99%);
  background: -ms-linear-gradient(top, #dedede 0, #c8c8c8 99%);
  background: -moz-linear-gradient(top, #dedede 0, #c8c8c8 99%);
  background: -o-linear-gradient(top, #dedede 0, #c8c8c8 99%);
  background: linear-gradient(#dedede 0, #c8c8c8 99%);
  border: 1px solid #999;
  border-radius: 3px;
  width: 300px;
  height: 10px;
  box-shadow: 0 0 3px #aaa;
}

input.vis-configuration.vis-config-range::-moz-range-thumb {
  background: #385380;
  border: none;
  border-radius: 50%;
  width: 16px;
  height: 16px;
}

input.vis-configuration.vis-config-range:-moz-focusring {
  outline-offset: -1px;
  outline: 1px solid #fff;
}

input.vis-configuration.vis-config-range::-ms-track {
  color: #0000;
  background: none;
  border-width: 6px 0;
  border-color: #0000;
  width: 300px;
  height: 5px;
}

input.vis-configuration.vis-config-range::-ms-fill-lower {
  background: #777;
  border-radius: 10px;
}

input.vis-configuration.vis-config-range::-ms-fill-upper {
  background: #ddd;
  border-radius: 10px;
}

input.vis-configuration.vis-config-range::-ms-thumb {
  background: #385380;
  border: none;
  border-radius: 50%;
  width: 16px;
  height: 16px;
}

input.vis-configuration.vis-config-range:focus::-ms-fill-lower {
  background: #888;
}

input.vis-configuration.vis-config-range:focus::-ms-fill-upper {
  background: #ccc;
}

.vis-configuration-popup {
  text-align: center;
  color: #fff;
  background: #394c59d9;
  border: 2px solid #f2faff;
  border-radius: 4px;
  width: 150px;
  height: 30px;
  font-size: 14px;
  line-height: 30px;
  transition: opacity .3s ease-in-out;
  position: absolute;
}

.vis-configuration-popup:after, .vis-configuration-popup:before {
  content: " ";
  pointer-events: none;
  border: solid #0000;
  width: 0;
  height: 0;
  position: absolute;
  top: 50%;
  left: 100%;
}

.vis-configuration-popup:after {
  border-width: 8px;
  border-color: #88b7d500 #88b7d500 #88b7d500 #394c59d9;
  margin-top: -8px;
}

.vis-configuration-popup:before {
  border-width: 12px;
  border-color: #c2e1f500 #c2e1f500 #c2e1f500 #f2faff;
  margin-top: -12px;
}

div.vis-tooltip {
  visibility: hidden;
  white-space: nowrap;
  color: #000;
  pointer-events: none;
  z-index: 5;
  background-color: #f5f4ed;
  border: 1px solid #808074;
  border-radius: 3px;
  padding: 5px;
  font-family: verdana;
  font-size: 14px;
  position: absolute;
  box-shadow: 3px 3px 10px #0003;
}

.vis-current-time {
  z-index: 1;
  pointer-events: none;
  background-color: #ff7f6e;
  width: 2px;
}

.vis-rolling-mode-btn {
  cursor: pointer;
  opacity: .8;
  color: #fff;
  text-align: center;
  background: #3876c2;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: 28px;
  font-weight: 700;
  position: absolute;
  top: 7px;
  right: 20px;
}

.vis-rolling-mode-btn:before {
  content: "⛶";
}

.vis-rolling-mode-btn:hover {
  opacity: 1;
}

.vis-custom-time {
  cursor: move;
  z-index: 1;
  background-color: #6e94ff;
  width: 2px;
}

.vis-panel.vis-background.vis-horizontal .vis-grid.vis-horizontal {
  border-bottom: 1px solid;
  width: 100%;
  height: 0;
  position: absolute;
}

.vis-panel.vis-background.vis-horizontal .vis-grid.vis-minor {
  border-color: #e5e5e5;
}

.vis-panel.vis-background.vis-horizontal .vis-grid.vis-major {
  border-color: #bfbfbf;
}

.vis-data-axis .vis-y-axis.vis-major {
  color: #4d4d4d;
  white-space: nowrap;
  width: 100%;
  position: absolute;
}

.vis-data-axis .vis-y-axis.vis-major.vis-measure {
  visibility: hidden;
  border: 0;
  width: auto;
  margin: 0;
  padding: 0;
}

.vis-data-axis .vis-y-axis.vis-minor {
  color: #bebebe;
  white-space: nowrap;
  width: 100%;
  position: absolute;
}

.vis-data-axis .vis-y-axis.vis-minor.vis-measure {
  visibility: hidden;
  border: 0;
  width: auto;
  margin: 0;
  padding: 0;
}

.vis-data-axis .vis-y-axis.vis-title {
  color: #4d4d4d;
  white-space: nowrap;
  text-align: center;
  position: absolute;
  bottom: 20px;
}

.vis-data-axis .vis-y-axis.vis-title.vis-measure {
  visibility: hidden;
  width: auto;
  margin: 0;
  padding: 0;
}

.vis-data-axis .vis-y-axis.vis-title.vis-left {
  -webkit-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  -o-transform-origin: 0 0;
  transform-origin: 0 100%;
  bottom: 0;
  transform: rotate(-90deg);
}

.vis-data-axis .vis-y-axis.vis-title.vis-right {
  transform-origin: 100% 100%;
  bottom: 0;
  transform: rotate(90deg);
}

.vis-legend {
  background-color: #f7fcffa6;
  border: 1px solid #b3b3b3;
  padding: 5px;
  box-shadow: 2px 2px 10px #9a9a9a8c;
}

.vis-legend-text {
  white-space: nowrap;
  display: inline-block;
}

.vis-item {
  color: #1a1a1a;
  z-index: 1;
  background-color: #d5ddf6;
  border-width: 1px;
  border-color: #97b0f8;
  display: inline-block;
  position: absolute;
}

.vis-item.vis-selected {
  z-index: 2;
  background-color: #fff785;
  border-color: #ffc200;
}

.vis-editable.vis-selected {
  cursor: move;
}

.vis-item.vis-point.vis-selected {
  background-color: #fff785;
}

.vis-item.vis-box {
  text-align: center;
  border-style: solid;
  border-radius: 2px;
}

.vis-item.vis-point {
  background: none;
}

.vis-item.vis-dot {
  border-style: solid;
  border-width: 4px;
  border-radius: 4px;
  padding: 0;
  position: absolute;
}

.vis-item.vis-range {
  box-sizing: border-box;
  border-style: solid;
  border-radius: 2px;
}

.vis-item.vis-background {
  box-sizing: border-box;
  background-color: #d5ddf666;
  border: none;
  margin: 0;
  padding: 0;
}

.vis-item .vis-item-overflow {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  position: relative;
  overflow: hidden;
}

.vis-item-visible-frame {
  white-space: nowrap;
}

.vis-item.vis-range .vis-item-content {
  display: inline-block;
  position: relative;
}

.vis-item.vis-background .vis-item-content {
  display: inline-block;
  position: absolute;
}

.vis-item.vis-line {
  border-left-style: solid;
  border-left-width: 1px;
  width: 0;
  padding: 0;
  position: absolute;
}

.vis-item .vis-item-content {
  white-space: nowrap;
  box-sizing: border-box;
  padding: 5px;
}

.vis-item .vis-onUpdateTime-tooltip {
  color: #fff;
  text-align: center;
  white-space: nowrap;
  -o-transition: .4s;
  background: #4f81bd;
  border-radius: 1px;
  width: 200px;
  padding: 5px;
  transition: all .4s;
  position: absolute;
}

.vis-item .vis-delete, .vis-item .vis-delete-rtl {
  box-sizing: border-box;
  cursor: pointer;
  -o-transition: background .2s linear;
  width: 24px;
  height: 24px;
  padding: 0 5px;
  transition: background .2s linear;
  position: absolute;
  top: 0;
}

.vis-item .vis-delete {
  right: -24px;
}

.vis-item .vis-delete-rtl {
  left: -24px;
}

.vis-item .vis-delete-rtl:after, .vis-item .vis-delete:after {
  content: "×";
  color: red;
  -o-transition: color .2s linear;
  font-family: arial, sans-serif;
  font-size: 22px;
  font-weight: 700;
  transition: color .2s linear;
}

.vis-item .vis-delete-rtl:hover, .vis-item .vis-delete:hover {
  background: red;
}

.vis-item .vis-delete-rtl:hover:after, .vis-item .vis-delete:hover:after {
  color: #fff;
}

.vis-item .vis-drag-center {
  cursor: move;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.vis-item.vis-range .vis-drag-left {
  cursor: w-resize;
  width: 24px;
  min-width: 2px;
  max-width: 20%;
  height: 100%;
  position: absolute;
  top: 0;
  left: -4px;
}

.vis-item.vis-range .vis-drag-right {
  cursor: e-resize;
  width: 24px;
  min-width: 2px;
  max-width: 20%;
  height: 100%;
  position: absolute;
  top: 0;
  right: -4px;
}

.vis-range.vis-item.vis-readonly .vis-drag-left, .vis-range.vis-item.vis-readonly .vis-drag-right {
  cursor: auto;
}

.vis-itemset {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  position: relative;
}

.vis-itemset .vis-background, .vis-itemset .vis-foreground {
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: visible;
}

.vis-axis {
  z-index: 1;
  width: 100%;
  height: 0;
  position: absolute;
  left: 0;
}

.vis-foreground .vis-group {
  box-sizing: border-box;
  border-bottom: 1px solid #bfbfbf;
  position: relative;
}

.vis-foreground .vis-group:last-child {
  border-bottom: none;
}

.vis-nesting-group {
  cursor: pointer;
}

.vis-nested-group {
  background: #f5f5f5;
}

.vis-label.vis-nesting-group.expanded:before {
  content: "▼";
}

.vis-label.vis-nesting-group.collapsed-rtl:before {
  content: "◀";
}

.vis-label.vis-nesting-group.collapsed:before {
  content: "▶";
}

.vis-overlay {
  z-index: 10;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.vis-labelset {
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
}

.vis-labelset .vis-label {
  color: #4d4d4d;
  box-sizing: border-box;
  border-bottom: 1px solid #bfbfbf;
  width: 100%;
  position: relative;
  top: 0;
  left: 0;
}

.vis-labelset .vis-label.draggable {
  cursor: pointer;
}

.vis-labelset .vis-label:last-child {
  border-bottom: none;
}

.vis-labelset .vis-label .vis-inner {
  padding: 5px;
  display: inline-block;
}

.vis-labelset .vis-label .vis-inner.vis-hidden {
  padding: 0;
}

.vis-panel {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  position: absolute;
}

.vis-panel.vis-bottom, .vis-panel.vis-center, .vis-panel.vis-left, .vis-panel.vis-right, .vis-panel.vis-top {
  border: 1px #bfbfbf;
}

.vis-panel.vis-center, .vis-panel.vis-left, .vis-panel.vis-right {
  border-top-style: solid;
  border-bottom-style: solid;
  overflow: hidden;
}

.vis-left.vis-panel.vis-vertical-scroll, .vis-right.vis-panel.vis-vertical-scroll {
  height: 100%;
  overflow: hidden scroll;
}

.vis-left.vis-panel.vis-vertical-scroll {
  direction: rtl;
}

.vis-left.vis-panel.vis-vertical-scroll .vis-content, .vis-right.vis-panel.vis-vertical-scroll {
  direction: ltr;
}

.vis-right.vis-panel.vis-vertical-scroll .vis-content {
  direction: rtl;
}

.vis-panel.vis-bottom, .vis-panel.vis-center, .vis-panel.vis-top {
  border-left-style: solid;
  border-right-style: solid;
}

.vis-background {
  overflow: hidden;
}

.vis-panel > .vis-content {
  position: relative;
}

.vis-panel .vis-shadow {
  width: 100%;
  height: 1px;
  position: absolute;
  box-shadow: 0 0 10px #000c;
}

.vis-panel .vis-shadow.vis-top {
  top: -1px;
  left: 0;
}

.vis-panel .vis-shadow.vis-bottom {
  bottom: -1px;
  left: 0;
}

.vis-graph-group0 {
  fill: #4f81bd;
  fill-opacity: 0;
  stroke-width: 2px;
  stroke: #4f81bd;
}

.vis-graph-group1 {
  fill: #f79646;
  fill-opacity: 0;
  stroke-width: 2px;
  stroke: #f79646;
}

.vis-graph-group2 {
  fill: #8c51cf;
  fill-opacity: 0;
  stroke-width: 2px;
  stroke: #8c51cf;
}

.vis-graph-group3 {
  fill: #75c841;
  fill-opacity: 0;
  stroke-width: 2px;
  stroke: #75c841;
}

.vis-graph-group4 {
  fill: #ff0100;
  fill-opacity: 0;
  stroke-width: 2px;
  stroke: #ff0100;
}

.vis-graph-group5 {
  fill: #37d8e6;
  fill-opacity: 0;
  stroke-width: 2px;
  stroke: #37d8e6;
}

.vis-graph-group6 {
  fill: #042662;
  fill-opacity: 0;
  stroke-width: 2px;
  stroke: #042662;
}

.vis-graph-group7 {
  fill: #00ff26;
  fill-opacity: 0;
  stroke-width: 2px;
  stroke: #00ff26;
}

.vis-graph-group8 {
  fill: #f0f;
  fill-opacity: 0;
  stroke-width: 2px;
  stroke: #f0f;
}

.vis-graph-group9 {
  fill: #8f3938;
  fill-opacity: 0;
  stroke-width: 2px;
  stroke: #8f3938;
}

.vis-timeline .vis-fill {
  fill-opacity: .1;
  stroke: none;
}

.vis-timeline .vis-bar {
  fill-opacity: .5;
  stroke-width: 1px;
}

.vis-timeline .vis-point {
  stroke-width: 2px;
  fill-opacity: 1;
}

.vis-timeline .vis-legend-background {
  stroke-width: 1px;
  fill-opacity: .9;
  fill: #fff;
  stroke: #c2c2c2;
}

.vis-timeline .vis-outline {
  stroke-width: 1px;
  fill-opacity: 1;
  fill: #fff;
  stroke: #e5e5e5;
}

.vis-timeline .vis-icon-fill {
  fill-opacity: .3;
  stroke: none;
}

.vis-time-axis {
  position: relative;
  overflow: hidden;
}

.vis-time-axis.vis-foreground {
  width: 100%;
  top: 0;
  left: 0;
}

.vis-time-axis.vis-background {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.vis-time-axis .vis-text {
  color: #4d4d4d;
  box-sizing: border-box;
  white-space: nowrap;
  padding: 3px;
  position: absolute;
  overflow: hidden;
}

.vis-time-axis .vis-text.vis-measure {
  visibility: hidden;
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;
  position: absolute;
}

.vis-time-axis .vis-grid.vis-vertical {
  border-left: 1px solid;
  position: absolute;
}

.vis-time-axis .vis-grid.vis-vertical-rtl {
  border-right: 1px solid;
  position: absolute;
}

.vis-time-axis .vis-grid.vis-minor {
  border-color: #e5e5e5;
}

.vis-time-axis .vis-grid.vis-major {
  border-color: #bfbfbf;
}

.vis-timeline {
  box-sizing: border-box;
  border: 1px solid #bfbfbf;
  margin: 0;
  padding: 0;
  position: relative;
  overflow: hidden;
}
/*# sourceMappingURL=index.bd6280b8.css.map */
