.vis-timeline {
  border-width: 1px 0 0 0;
}

.vis-text {
  font-size: 10px;
}

.vis-text.vis-major {
  margin-top: -3px;
}

.vis-item.vis-background {
  background-color: rgba(208,2,27,0.05);
}

.vis-custom-time {
  background-color: #56AD56;
}

.vis-custom-time:after {
  content: "";
  position: absolute;
  top: 0;
  left: -8px;
  border-width: 9px 9px 0;
  border-style: solid;
  border-color: #56AD56 transparent;
  display: block;
  width: 0;
}

.vis-time-axis .vis-grid.vis-major {
  border-color: rgba(0,0,0,.2);
}

.vis-grid.vis-minor {
  top: auto !important;
  bottom: 0;
  height: 10px !important;
  border-color: #00AAE4 !important;
}

.vis-panel.vis-bottom {
  top: 27px !important;
}

.vis-text.vis-minor {
  margin-left: -15px;
}

.vis-panel.vis-center {
  border: 0 none;
  height: 60px !important;
}

.vis-item.vis-background {
  height: 60px !important;
}

.vis-content {
  top: 0 !important;
}

.vis-itemset {
  height: 60px !important;
}

.vis-shadow {
  display: none !important;
}

.vis-current-time {
  background: #D0021B;
}

.vis-item.vis-background.timeBackground {
  background: rgba(0,190,255,0.5) !important;
  bottom: 0;
  height: 5px !important;
  right: 0;
  z-index: -200;
}

.vis-item.vis-background.exception {
  top: 12px;
  height: 10px !important;
  border-radius: 10px;
  border-right: '1px solid';
  border-left: '1px solid';
}
