.wysiwyg-toolbar {
  display: flex;
  border-style: solid;
  border-width: 1px;
  border-color: #ebebeb;
  border-radius: 4px;
  height: 34px;
  line-height: 34px;
  padding: 5px;
  margin-bottom: 5px;
  font-family: 'system-ui', 'Helvetica Neue', Helvetica,
    Arial, sans-serif;
  font-size: 20px;
  font-weight: 400;
}

.wysiwyg-toolbar .rdw-block-wrapper,
.wysiwyg-toolbar .rdw-inline-wrapper,
.wysiwyg-toolbar .rdw-list-wrapper {
  display: flex;
}

.rdw-link-wrapper {
  display: flex;
}

.rdw-link-modal-input {
  display: flex;
  border-style: solid;
  border-width: 1px;
  border-color: #ebebeb;
  border-radius: 3px;
  width: 90%;
}

.rdw-link-modal-buttonsection {
  display: flex;
  justify-content: space-between;
  height: 34px;
  padding: 5px;
}

.rdw-link-modal-btn {
  align-items: center;
  display: flex;
  justify-content: center;
  font-size: 12px;
  font-weight: 500;
  height: 35px;
  line-height: 40px;
  padding: 0 20px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-width: 0;
  border-style: none;
  border-radius: 4;
  cursor: 'pointer';
  color: rgb(84, 84, 84);
}
.rdw-link-modal-btn:first-child {
  background-color: rgb(0, 170, 228);
  color: white;
}

img.rdw-link-decorator-icon {
  height: 10px;
  cursor: pointer;
  padding-left: 2px;
  padding-right: 2px;
}

.popup-modal {
  display: block;
  position: absolute;
  justify-content: center;
  border-style: solid;
  border-width: 1px;
  border-color: #ebebeb;
  border-radius: 3px;
  width: 15%;
  padding: 5px;
  margin-left: 35px;
  background-color: white;
  margin-top: -6px;
  font-size: 12px;
  font-family: 'system-ui', 'Helvetica Neue', Helvetica,
    Arial, sans-serif;
  font-weight: 400;
}

.wysiwyg-toolbar .rdw-option-wrapper,
.wysiwyg-toolbar .rdw-block-wrapper {
  display: flex;
  justify-content: center;
  border-style: solid;
  border-width: 1px;
  border-color: #ebebeb;
  border-radius: 3px;
  min-width: 30px;
  text-align: center;
  margin-right: 4px;
}

.wysiwyg-toolbar .rdw-option-wrapper img {
  align-self: center;
}

.wysiwyg-toolbar .rdw-block-wrapper {
  cursor: pointer;
}

.wysiwyg-toolbar .rdw-option-wrapper:hover {
  cursor: pointer;
}

.wysiwyg-toolbar .rdw-block-wrapper {
  padding-left: 5px;
  padding-right: 5px;
}

.wysiwyg-toolbar .rdw-option-active {
  background: #ebebeb;
}

.rdw-block-dropdown {
  font-size: 12px;
  width: 80px;
}

.rdw-block-dropdown .rdw-dropdown-selectedtext {
  display: block;
  white-space: nowrap;
}

.rdw-editor-wrapper {
  display: block;
  width: 100%;
}

.blockOptions {
  position: relative;
  list-style: none;
  margin-top: 3px;
  margin-left: -3px;
  margin-right: -3px;
  padding: 0;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.2) 0 4px 14px;
  -webkit-box-shadow: rgba(0, 0, 0, 0.2) 0 4px 14px;
  -moz-box-shadow: rgba(0, 0, 0, 0.2) 0 4px 14px;
  z-index: 1000;
}

.blockOptions li {
  border-bottom: 1px solid rgba(235, 235, 235);
}

.blockOptions li:hover {
  background: rgb(246, 246, 246);
}

.wysiwyg-editor {
  display: block;
  border-style: solid;
  border-width: 1px;
  border-color: #ebebeb;
  border-radius: 4px;
  padding: 10px;
  box-sizing: border-box;
  font-family: 'system-ui', 'Helvetica Neue', Helvetica,
    Arial, sans-serif;
  font-size: 12px;
  min-height: 160px;
}

.wysiwyg-editor h1 {
  font-size: 20px;
  margin-top: 0;
  word-break: break-word;
}

.wysiwyg-editor h2 {
  font-size: 16px;
  color: #333;
  margin-top: 0;
  word-break: break-word;
}

.wysiwyg-editor ul,
ol {
  padding-left: 20px;
  word-break: break-word;
}

.wysiwyg-editor span {
  font-family: 'system-ui', 'Helvetica Neue', Helvetica,
    Arial, sans-serif;
  word-break: break-word;
  height: 20px;
}

.wysiwyg-editor span a {
  font-family: 'system-ui', 'Helvetica Neue', Helvetica,
    Arial, sans-serif;
  word-break: break-word;
  color: rgb(0, 170, 228);
}

.wysiwyg-editor span a:hover {
  font-family: 'system-ui', 'Helvetica Neue', Helvetica,
    Arial, sans-serif;
  word-break: break-word;
  color: rgb(0, 170, 228);
}
