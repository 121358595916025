::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #aaa;
}
::-moz-placeholder { /* Firefox 19+ */
  color: #aaa;
}
:-ms-input-placeholder { /* IE 10+ */
  color: #aaa;
}
:-moz-placeholder { /* Firefox 18- */
  color: #aaa;
}
