/*
 * Override date picker styles
 */

.lh-date-time-picker .DayPicker {
  font-size: 13px;
  outline: none;
}

.lh-date-time-picker .DayPicker-Caption > div {
  font-size: 12px;
  text-align: center;
}

.lh-date-time-picker .DayPicker-Day {
  background: #FFF;
  border-radius: 0 !important;
  border: 1px solid #EEE;
}

.lh-date-time-picker .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  background: #00AAE4;
  color: #FFF;
}

.lh-date-time-picker .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
  background: #00AAE4;
}

.lh-date-time-picker .DayPicker-Day.DayPicker-Day--disabled, .DayPicker-Day.DayPicker-Day--beforeSelectableRange {
  background: #F5F5F5;
  color: #dce0e0;
  cursor: not-allowed;
}

.lh-date-time-picker .DayPicker-Day.DayPicker-Day--activeRangeEnd:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):not(.DayPicker-Day--selected) {
  background: #F0FBFF;
}

.lh-date-time-picker .DayPicker-Day.DayPicker-Day--afterActiveRange {
  background: #FFF;
  color: #dce0e0;
  cursor: not-allowed;
}
.lh-date-time-picker .DayPicker-Day--hoverRange:not(.DayPicker-Day--outside):not(.DayPicker-Day--selected) {
  background: #F0FBFF !important;
}


.lh-date-time-picker .DayPicker-NavBar {
}

.lh-date-time-picker .DayPicker-NavButton {
  position: absolute;
  top: 1em;
  right: 1.5em;
  left: auto;

  display: inline-block;
  margin-top: 2px;
  width: 1.25em;
  height: 1.25em;
  background-position: center;
  background-size: 50%;
  background-repeat: no-repeat;
  color: #8B9898;
  cursor: pointer;
}

.lh-date-time-picker .DayPicker-NavButton:hover {
  opacity: 0.8;
}

.lh-date-time-picker .DayPicker-NavButton--prev {
  margin-right: 1.5em;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAVVJREFUWAnN2G0KgjAYwPHpGfRkaZeqvgQaK+hY3SUHrk1YzNLay/OiEFp92I+/Mp2F2Mh2lLISWnflFjzH263RQjzMZ19wgs73ez0o1WmtW+dgA01VxrE3p6l2GLsnBy1VYQOtVSEH/atCCgqpQgKKqYIOiq2CBkqtggLKqQIKgqgCBjpJ2Y5CdJ+zrT9A7HHSTA1dxUdHgzCqJIEwq0SDsKsEg6iqBIEoq/wEcVRZBXFV+QJxV5mBtlDFB5VjYTaGZ2sf4R9PM7U9ZU+lLuaetPP/5Die3ToO1+u+MKtHs06qODB2zBnI/jBd4MPQm1VkY79Tb18gB+C62FdBFsZR6yeIo1YQiLJWMIiqVjQIu1YSCLNWFgijVjYIuhYYCKoWKAiiFgoopxYaKLUWOii2FgkophYp6F3r42W5A9s9OcgNvva8xQaysKXlFytoqdYmQH6tF3toSUo0INq9AAAAAElFTkSuQmCC');
}

.lh-date-time-picker .DayPicker-NavButton--next {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAXRJREFUWAnN119ugjAcwPHWzJ1gnmxzB/BBE0n24m4xfNkTaOL7wOtsl3AXMMb+Vjaa1BG00N8fSEibPpAP3xAKKs2yjzTPH9RAjhEo9WzPr/Vm8zgE0+gXATAxxuxtqeJ9t5tIwv5AtQAApsfT6TPdbp+kUBcgVwvO51KqVhMkXKsVJFXrOkigVhCIs1Y4iKlWZxB1rX4gwlpRIIpa8SDkWmggrFq4IIRaJKCYWnSgnrXIQV1r8YD+1Vrn+bReagysIFfLABRt31v8oBu1xEBttfRbltmfjgEcWh9snUS2kNdBK6WN1vrOWxObWsz+fjxevsxmB1GQDfINWiev83nhaoiB/CoOU438oPrhXS0WpQ9xc1ZQWxWHqUYe0I0qrKCQKjygDlXIQV2r0IF6ViEBxVTBBSFUQQNhVYkHIVeJAtkNsbQ7c1LtzP6FsObhb2rCKv7NBIGoq4SDmKoEgTirXAcJVGkFSVVpgoSrXICGUMUH/QBZNSUy5XWUhwAAAABJRU5ErkJggg==');
}

.lh-date-time-picker .DayPicker-NavButton--interactionDisabled {
  display: none;
}